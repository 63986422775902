import {
  CURR_PATH_CHANGED,
  SUBMIT_PREREGISTER_FORM,
  SUBMIT_WORKSHOP_REGISTER_FORM,
  SUBMIT_CONTACT_FORM,
  SUBMIT_INSTRUCTOR_MENTOR_FORM,
  SUBMIT_HIRING_FORM
} from './types';
import { db } from '../utils/firebase';

function _currPathChanged(path) {
  return {
    type: CURR_PATH_CHANGED,
    payload: path
  };
}

function _submitPreRegisterForm(data) {
  let request = db
    .collection('pre-register')
    .doc()
    .set({ ...data, date: new Date() })
    .then(function () {
      console.log('Document successfully written!');
      return Promise.resolve(true);
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      return Promise.resolve(false);
    });

  return {
    type: SUBMIT_PREREGISTER_FORM,
    payload: request
  };
}

function _submitWorkshopRegisterForm(data) {
  let request = db
    .collection('workshop-register')
    .doc()
    .set({ ...data, date: new Date() })
    .then(function () {
      console.log('Document successfully written!');
      return Promise.resolve(true);
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      return Promise.resolve(false);
    });

  return {
    type: SUBMIT_WORKSHOP_REGISTER_FORM,
    payload: request
  };
}

function _submitContactForm(data) {
  let request = db
    .collection('contact')
    .doc()
    .set({ ...data, date: new Date() })
    .then(function () {
      console.log('Document successfully written!');
      return Promise.resolve(true);
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      return Promise.resolve(false);
    });

  return {
    type: SUBMIT_CONTACT_FORM,
    payload: request
  };
}

function _submitInstructorMentorForm(data, type) {
  let request = db
    .collection(type)
    .doc()
    .set({ ...data, date: new Date() })
    .then(function () {
      console.log('Document successfully written!');
      return Promise.resolve(true);
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      return Promise.resolve(false);
    });

  return {
    type: SUBMIT_INSTRUCTOR_MENTOR_FORM,
    payload: request
  };
}

function _submitHiringForm(data) {
  let request = db
    .collection('hire')
    .doc()
    .set({ ...data, date: new Date() })
    .then(function () {
      console.log('Document successfully written!');
      return Promise.resolve(true);
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      return Promise.resolve(false);
    });

  return {
    type: SUBMIT_HIRING_FORM,
    payload: request
  };
}

export {
  _currPathChanged as currPathChanged,
  _submitPreRegisterForm as submitPreRegisterForm,
  _submitWorkshopRegisterForm as submitWorkshopRegisterForm,
  _submitContactForm as submitContactForm,
  _submitInstructorMentorForm as submitInstructorMentorForm,
  _submitHiringForm as submitHiringForm
};
