import React from 'react';
import '../css/intro.css';
import Paragraphs from '../components/Paragraphs';
import Button from '../components/Button';

let Intro = (props) => {
  let { sectionId, h2Heading, intro } = props,
    { heading, text, cta, link, img } = intro;
  return (
    <section id={sectionId} className="intro">
      <div className="left">
        {h2Heading ? <h2>{heading}</h2> : <h1>{heading}</h1>}
        <Paragraphs data={text} />
        <div className="intro__cta-button">
          {cta && cta.length > 0 && cta.map
            ? cta.map((btn, btnId) => <Button key={btnId} {...btn} />)
            : link &&
              cta && (
                <a className="btn btn-outline btn-outline-blue" href={link}>
                  {cta}
                </a>
              )}
        </div>
      </div>
      <div className="right">
        <img src={img} alt="Learn To Code at WE3 Academy" />
      </div>
    </section>
  );
};

export default Intro;
