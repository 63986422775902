import React from 'react';
import { withRouter } from 'react-router-dom';

const Button = withRouter(({ history, type, customClass, customText, customLink }) => {
    const buttons = {
        email: {
            text: "Email", 
            href: "mailto:admin@we3academy.com",
            defaultClass: "btn btn-outline btn-outline-blue margin-right-15 black" 
        },
        call: {
            text: "Call", 
            href: "tel:+919967025257",
            defaultClass: "btn btn-filled btn-filled-blue" 
        },
        apply: {
            text: "Register", 
            link: "/apply",
            defaultClass: "btn btn-filled btn-filled-blue"
        },
        faq: {
            text: "FAQs", 
            link: "/faq",
            defaultClass: "btn btn-outline btn-outline-blue"
        },
        viewCourses: {
            text: "View Courses", 
            link: "/home#courses",
            defaultClass: "btn btn-outline btn-outline-blue margin-right-15"
        },
        company: {
            text: "Learn More", 
            link: "/company",
            defaultClass: "btn btn-outline btn-outline-blue margin-right-15"
        },
        tour: {
            text: "Schedule a Tour",
            link: "/tour",
            defaultClass: "btn btn-filled btn-filled-blue"
        }
    }

    if(type && buttons[type]) {
        let btn = buttons[type];
        if(btn.href) {
            return (
                <a href={btn.href} className={customClass ? customClass : btn.defaultClass}>{customText ? customText : btn.text}</a>
            )
        }
        else if(btn.link) {
            return (
                <button className={customClass ? customClass : btn.defaultClass} 
                        onClick={() => history.push(customLink ? customLink : btn.link)}>
                {customText ? customText : btn.text}</button>
            )
        }
    }
    return null;
});

export default Button;