import React from 'react';

let Paragraphs = (props) => {
  return (
    <React.Fragment>
      {props &&
        props.data &&
        props.data.length > 0 &&
        props.data.map((para, paraIdx) => (
          <p className={props.light ? 'text-light' : ''} key={paraIdx}>
            {para}
          </p>
        ))}
    </React.Fragment>
  );
};

export default Paragraphs;
