import React from 'react';
import Paragraphs from './Paragraphs';
import Spacer from './Spacer';
import { ctaBlock } from '../static/data';
import Button from './Button';

function CTA(props) {
  let styleType = ctaBlock[props.styleType],
    useCta =
      props.customCta && props.customCta.length > 0
        ? props.customCta
        : styleType.cta && styleType.cta.length > 0
        ? styleType.cta
        : null;
  return (
    <div
      className="cta-block breakout"
      style={{
        padding: '40px 0 60px 0',
        backgroundColor: 'var(--bg-light-yellow)'
      }}
    >
      <div className="container">
        <h4 style={{ fontSize: '1.3em' }}>{styleType.heading}</h4>
        <Paragraphs data={styleType.text} />
        <Spacer px={20} />
        {props.children
          ? props.children
          : useCta &&
            useCta.length > 0 &&
            useCta.map((btn, btnIdx) => <Button key={btnIdx} {...btn} />)}
      </div>
    </div>
  );
}

export default CTA;
