import React from 'react';
import '../css/imageBlock.css';

let ImageBlock = (props) => {
    return (
        <div className="image-block">
            {
                props && props.images && props.images.length > 0 && props.images.map((img, imgIndx) => (
                    <img key={imgIndx} src={img.src} alt={img.alt} />
                ))
            }
        </div>
    )
}

export default ImageBlock