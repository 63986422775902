import React from 'react';
import { socialBlock } from '../static/data';
import SocialIcons from './SocialIcons';
import Paragraphs from './Paragraphs';

const SocialBlock = (props) => {
  return (
    <div className="social-cta" style={{ margin: '60px 0 40px 0' }}>
      <Paragraphs data={socialBlock[props.styleType].text} />
      <SocialIcons
        justifyContent="space-between"
        customStyles={{ color: 'var(--text-light)', fontSize: '1.8em' }}
        customOuterStyles={{ maxWidth: '350px' }}
      />
    </div>
  );
};

export default SocialBlock;
