import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Paragraphs from '../components/Paragraphs';
import ImageBlock from '../components/ImageBlock';
import { homeData } from '../static/data';
import CTA from '../components/CTA';

import '../css/home.css';
import SocialBlock from '../components/SocialBlock';
import ScrollToTop from '../components/ScrollToTop';
import Intro from '../components/Intro';
import Reasoning from '../components/Reasoning';
import Button from '../components/Button';
import SEO from '../components/SEO';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { currPathChanged } from '../actions/formActions';

let Home = withRouter((props) => {
  let { intro, reasoning, courses, about, imageBlock } = homeData;
  let { history } = props;
  useEffect(() => {
    currPathChanged('homeeee');
  }, []);

  return (
    <div id="home">
      <SEO
        title="WE3 Academy | Coding Bootcamp in Mumbai"
        description="At WE3 Academy, we are bridging the gap between education and employability with our in-person training courses designed to provide you with fundamental skills and real-world experiences to accelerate your career."
        keywords="WE3 Academy, WE3, Coding Bootcamp, Coding academy, coding school, web development bootcamp, education, education center, educational institute, best coding bootcamp, coding bootcamp in India, coding bootcamp in mumbai, best coding bootcamp in mumbai, code school, programming bootcamp, full stack web development course, full stack web development, dev bootcamp, code bootcamp,  career courses, digital courses, course, courses, career development, career development course, social media marketing, social media marketing course, social media, best courses in mumbai, social media courses in mumbai, career development courses in mumbai, part time courses, part time course, full time courses, computer programming, computer coding, training courses, training, in-person training, real world experiences, real work environment, employability, training institute, training bootcamp, training center"
      >
        <script type="application/ld+json">
          {`{
            "@context" : "http://schema.org",
            "@type" : "Website",
            "name" : "Home",
            "description" : "Our courses are designed to provide you with fundamental skills and real-world experiences to accelerate your career.",
            "url" : "https://we3academy.com/#courses"
          }`}
        </script>
      </SEO>
      <ScrollToTop />
      <Intro h2Heading={true} sectionId="intro" intro={intro} />
      <Reasoning sectionId="reasoning" reasoning={reasoning} />
      <section id="courses">
        <h2 className="blue uppercase">{courses.heading}</h2>
        <Paragraphs data={courses.text} />
        {courses.types.map((courseType, index) => (
          <div className="course__type" key={index}>
            <h3 className="yellow">{courseType.heading}</h3>
            <Paragraphs light={true} data={courseType.text} />
            <div className="course__type-inner">
              {courseType.subType.map((subType, subIndex) => (
                <div key={subIndex} className="course__subType">
                  <p>
                    <span className="tag">{subType.duration}</span>
                    {subType.duarionHours && (
                      <span>
                        &nbsp;
                        <span className="tag">{subType.duarionHours}</span>
                      </span>
                    )}
                    <span className="extra">{subType.extra}</span>
                  </p>
                  <h4>{subType.heading}</h4>
                  <p>{subType.details}</p>
                  <button
                    onClick={() => history.push(subType.link)}
                    className="btn btn-outline btn-outline-blue"
                  >
                    {subType.cta}
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
      <section id="about" className="breakout">
        <div className="container">
          <h1 className="blue">{about.heading}</h1>
          <Paragraphs data={about.text} />
          <Button type="company" />
          {/* <Button type="tour" /> */}
        </div>
      </section>
      <div className="breakout">
        <ImageBlock images={imageBlock} />
      </div>
      <SocialBlock styleType="style1" />
      <section id="section-cta">
        <CTA styleType="style1" />
      </section>
    </div>
  );
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      currPathChanged
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return { formState: state && state.form ? state.form : null };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
