import React from 'react';
import Paragraphs from '../components/Paragraphs';

import '../css/reasoning.css';

let Reasoning = (props) => {
  let { sectionId, reasoning } = props;
  return (
    <section id={sectionId} className="reasoning-container breakout">
      <div className="reasoning container">
        <h2 className="heading blue">{reasoning.heading}</h2>
        <div className="reasoning__grid">
          {reasoning.grid.map((gridItem, index) => (
            <div className="reasoning__grid-item" key={index}>
              <div className="reasoning__grid-item-inner image-container">
                <img
                  src={gridItem.image}
                  alt={gridItem.imageAlt ? gridItem.imageAlt : gridItem.heading}
                />
              </div>
              <div className="reasoning__grid-item-inner">
                <h3>{gridItem.heading}</h3>
                <Paragraphs data={gridItem.text} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reasoning;
